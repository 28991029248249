<template>
    <div class="spinner-container" v-if="isViewerLoading">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <PropertiesGrid v-else :dataObject="props.dataObject" hideOkCancel>
        <template v-for="(group, index) in groupedAttributes" :key="index">
            <PropertiesGroup :caption="$t(group.name)" :fieldName="group.name">
            <template v-for="attribute in group.attributes" :key="attribute.Field">
                <PropertiesItem :caption="attribute.Label?.value ?? attribute.Label" v-if="(props.dataObject.current.hasOwnProperty(attribute.Field) && !hiddenFields.includes(attribute.Field))" :fieldName="attribute.Field" :required="attribute.Required">
                    <ODataLookup v-if="attribute.InputType == 'ODataLookup'" :bind="sel => {attribute.Bind(sel); saveDataObject()}" :disabled="disabledDO || attribute.Disabled" v-model="props.dataObject.current[attribute.Field]" class="border-0 w-100" 
                        :data-object="attribute.DataObject" :columns="attribute.Columns" :whereClause="attribute.WhereClause" :multiselect="attribute.MultiSelect" :bindClear="attribute.BindClear" 
                        :class="{'border border-danger':attribute.Required  && !props.dataObject.current[attribute.Field]}" @beforeopen="attribute.beforeopen && attribute.beforeopen()" />
                    <ODataLookup v-if="attribute.InputType == 'ODataLookupCheckBoxes'" :bind="sel => setCheckBoxValues(sel, attribute.Field, attribute.Columns[0].field)" 
                                :itemLoaded="lkpItem => checkBoxesIsSelected(lkpItem, props.dataObject.current[attribute.Field], attribute.Columns[0].field)" :disabled="disabledDO || attribute.Disabled" 
                                :value="getCheckboxesJsonValue(props.dataObject.current[attribute.Field])" class="border-0 w-100" :data-object="attribute.DataObject" :columns="attribute.Columns" 
                                :whereClause="attribute.WhereClause" multiselect
                                :class="{'border border-danger':attribute.Required  && !props.dataObject.current[attribute.Field]}"/>
                    <OPersonsLookup v-if="attribute.InputType == 'OPersonsLookup'" :disabled="(disabledDO || attribute.Disabled) && attribute.Field != 'ObsoleteReason'" :bind="sel => {attribute.Bind(sel); saveDataObject()}" v-model="props.dataObject.current[attribute.Field]" class="border-0 w-100" :data-object="attribute.DataObject" :columns="attribute.Columns" :whereClause="attribute.WhereClause" :multiselect="attribute.MultiSelect" :bindClear="attribute.BindClear" :class="{'border border-danger':attribute.Required && !props.dataObject.current[attribute.Field]}"/>
                    <input v-if="attribute.InputType == 'input'" type="text" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field]" class="border-0 w-100" :disabled="disabledDO || attribute.Disabled" :class="{'border border-danger': attribute.Required && !props.dataObject.current[attribute.Field]}">
                    <OTextArea v-if="attribute.InputType == 'OTextArea'" @blur="saveDataObject" :renderHTML="attribute.RenderHTML" :disabled="disabledDO || attribute.Disabled" rows="attribute.Rows" class="border-0 w-100" v-model="props.dataObject.current[attribute.Field]" :class="{'border border-danger':attribute.Required && !props.dataObject.current[attribute.Field]}"> </OTextArea>
                    <o-date-picker v-if="attribute.InputType == 'o-date-picker'" class="border-0 w-100" :callback="saveDataObject" v-model="props.dataObject.current[attribute.Field]" :disabled="disabledDO || attribute.Disabled" format="Short Date" :class="{'border-0 w-100':true,'border border-danger': attribute.Required && !props.dataObject.current[attribute.Field]}"></o-date-picker>
                    <div class="d-flex" v-if="attribute.InputType == 'input2x'">
                        <ONumberEditor  type="text" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field]" class="w-25 border-0" :disabled="disabledDO || attribute.Disabled" format="%"/>
                        <input type="text" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field2]" class="w-75 ms-1 border-0" :disabled="disabledDO || attribute.Disabled">
                    </div>
                </PropertiesItem>
            </template>
            </PropertiesGroup>
        </template>
         <PropertiesGroup :caption="$t('Properties')" :fieldName="$t('Properties')">
            <SystemProperties 
                viewName="aviw_ECM_DocumentsProperties" 
                uniqueTable="atbv_ECM_DocumentsProperties"
                parentFieldName="Document_ID"
                :propertyLookupDataObject="local_dsPropertiesLkp" 
                :itemId="props.dataObject.current.ID"
                objectsMode
                isStandardConfig
            />
        </PropertiesGroup>
    </PropertiesGrid>
</template>

<script setup>
import * as DataObjects from 'ECM.vue.components.CommonDBObjects.ts';
import { ref, computed } from 'vue';
import { getOrCreateDataObject, getDataObjectById } from 'o365.vue.ts'
import OPersonsLookup from 'o365.vue.components.PersonsLookup.vue'
import PropertiesGrid from 'o365.vue.components.PropertiesGrid.vue'
import PropertiesItem from 'o365.vue.components.PropertiesItem.vue'
import PropertiesGroup from 'o365.vue.components.PropertiesGroup.vue'
import context from 'o365.modules.Context.ts';
import SystemProperties from 'workflow.vue.components.Properties.vue'

const props = defineProps({
    dataObject: Object
})

function saveDataObject(){
    props.dataObject.save();
}

// attributes should be reloaded on change of fields that are part of whereclause on lookup. Or implement smarter whereClause approach (computed)

const requiredFields = ref("");
const isViewerLoading = ref(true);

const local_dsClassificationCode = getOrCreateDataObject(DataObjects.common_dsClassificationCode),
local_dsReviewClasses = getOrCreateDataObject(DataObjects.common_dsReviewClassLkp),
local_dsTopics = getOrCreateDataObject(DataObjects.common_dsTopics),
local_dsProjectCodes = getOrCreateDataObject(DataObjects.common_dsProjectCodes),
local_dsDisciplines = getOrCreateDataObject(DataObjects.common_dsDisciplines),
local_dsProjectPhases = getOrCreateDataObject(DataObjects.common_dsProjectPhases),
local_dsMainAreas = getOrCreateDataObject(DataObjects.common_dsMainAreas),
local_dsOrgUnitsLkp = getOrCreateDataObject(DataObjects.common_dsOrgUnitsLkp),
local_dsSubProjects = getOrCreateDataObject(DataObjects.common_dsSubProjects),
local_dsDistReqTypes =  getOrCreateDataObject(DataObjects.common_dsDistReqTypes),
local_dsDocumentPackages =  getOrCreateDataObject(DataObjects.common_dsDocumentPackages),
local_dsVoidReasons =  getOrCreateDataObject(DataObjects.common_dsVoidReasons),
local_dsRetentionCodes =  getOrCreateDataObject(DataObjects.common_dsRetentionCodes),
local_dsChecklistsLkp =  getOrCreateDataObject(DataObjects.common_dsChecklistsLkp),

local_dsErrors = getOrCreateDataObject({
    id: 'local_dsErrors',
    viewName: 'atbv_ECM_DocumentsErrors',
    appId: props.dataObject.appId,
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    masterDataObject_ID: props.dataObject.id,
    masterDetailDefinition: [{
        detailField: "Document_ID",
        masterField: "ID",
        operator: "equals"
    }],
    fields:
        [{name: "Error", type: "string"},
        {name: "FieldName", type: "string" }]
}),

local_dsPersonsWithAccess = getOrCreateDataObject({
    id: 'local_dsPersonsWithAccess',
    viewName: 'aviw_ECM_PersonsWithAccess',
    appId: props.dataObject.appId,
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    disableAutoLoad: true,
    masterDataObject_ID: props.dataObject.id,
    masterDetailDefinition: [{
        detailField: "Document_ID",
        masterField: "ID",
        operator: "equals"
    }],
    fields:
        [
            {name: "ID", type: "number"},
            {name: "Name", type: "string"},
            {name: "Document_ID", type: "string"},
            {name: "MobileNo", type: "string"},
            {name: "Email", type: "string"},
            {name: "Represents", type: "string"},
            {name: "LastName", type: "string", sortOrder: 1, sortDirection: "asc"}
        ]
}),
local_dsWBS = getOrCreateDataObject({
    id: 'local_dsWBS',
    viewName: 'aviw_Complete_WBSLookup',
    appId: props.dataObject.appId,
    maxRecords: -1,
    loadRecents: true,
    distinctRows: true,
    disableAutoLoad: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "Title", type: "string" },
        {name: "Parent", type: "string" },
        {name: "NameAndTitle", type: "string" }],
    masterDataObject_ID: props.dataObject.id,
    masterDetailDefinition: [{
        detailField: "OrgUnit_ID",
        masterField: "CostProject_ID",
        operator: "equals"
    }],
}),
local_dsHiddenPlannedFields = getOrCreateDataObject({
    id: 'local_dsHiddenPlannedFields',
    viewName: 'aviw_Arena_HiddenPlannedFields', // could be merged with hiddenfields in document view, but doing it like this now for these fields to remain in CT
    appId: props.dataObject.appId,
    maxRecords: 1,
    fields:
        [{name: "HiddenFields", type: "string" }]
}),

local_dsPropertiesLkp = getOrCreateDataObject({
    id: 'local_dsPropertiesLkp',
    viewName: 'sviw_System_PropertiesViewsWithDataTypes',
    appId: props.dataObject.appId,
    maxRecords: -1,
    whereClause: "ViewName IN ('aviw_ECM_Documents','aviw_ECM_DocumentsDetail')",
    loadRecents: true,
    distinctRows: true,
    disableAutoLoad: true,
    fields:
        [{name: "PropertyName", type: "string"}//,
        // {name: "PropertyNameTranslated", type: "string"},
        // { name: "DataType", type: "string" },
        // { name: "Description", type: "date" },
        // { name: "Title", type: "datetime" },
        ]
})


local_dsHiddenPlannedFields.load();

const hiddenFields = computed(()=>{
    if (!props.dataObject.state.isLoaded || !local_dsHiddenPlannedFields.state.isLoaded){
        return []
    }
    let allHiddenFields = 
        (props.dataObject.current.HiddenFields ? props.dataObject.current.HiddenFields : "") 
        + (props.dataObject.current.HiddenFields && local_dsHiddenPlannedFields.data[0].HiddenFields ? "," : "") 
        + (local_dsHiddenPlannedFields.data[0].HiddenFields ? local_dsHiddenPlannedFields.data[0].HiddenFields : "");

    return allHiddenFields ? allHiddenFields.split(',').map(element => element.trim()) : []
});

const attributes = ref([]);
const setDefaultAttributes = () => {
    attributes.value = [
        {
            Label: $t('Document Group'),
            Field: 'DocumentGroup',
            InputType: 'input',
            Disabled: true,
            Group:"General"
        },
        {
            Label: $t('Contractor Document No'),
            Field: "ContractorDocumentNo",
            InputType: 'input',
            Group:"Contractor Info"
        },
        {
            Label: $t('Client Document Number'),
            Field: "ClientDocumentNo",
            InputType: 'input',
            Group:"Client Info"
        },
        {
            Label: $t('Contractor Rev'),
            Field: "ContractorRev",
            InputType: 'input',
            Group:"Contractor Info"
        },
        {
            Label: $t('Client Rev'),
            Field: "ClientRev",
            InputType: 'input',
            Group:"Client Info"
        },
        {
            Label: $t('Responsible'),
            Field: "Responsible",
            InputType: 'ODataLookup',
            DataObject: local_dsPersonsWithAccess,
            Bind: (sel) => {props.dataObject.current.Responsible_ID = sel.ID, props.dataObject.current.Responsible = sel.Name;},
            Columns: [
                {field:'ID', headerName:'ID', width:100},
                {field:'Name', headerName:'Name', width:200},
                {field:'MobileNo', headerName:'MobileNo', width:100},
                {field:'Email', headerName:'Email', width:200},
                {field:'Represents', headerName:'Represents', width:200}
            ],
            Group:"General"
        },
        {
            Label: $t('Delivered'),
            Field: 'Delivered',
            InputType: 'o-date-picker',
            Disabled: true,
            Group:"General"
        },
        {
            Label: $t('Classification Code'),
            Field: "ClassificationNameAndTitle",
            RequiredField: "ClassificationCode",
            InputType: 'ODataLookup',
            DataObject: local_dsClassificationCode,
            Bind: (sel) => {props.dataObject.current.ClassificationCode_ID = sel.ID, props.dataObject.current.ClassificationNameAndTitle = sel.NameAndTitle},
            Columns: [
                {field:'ID', headerName:'ID', width:100},
                {field:'Name', headerName:'Name', width:200},
                {field:'Title', headerName:'Title', width:200}
            ],
            WhereClause: "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%' AND Closed IS NULL",
            Group:"General"
        },
        {
            Label: $t('Topic'),
            Field: "Topic",
            RequiredField: "Topic",
            InputType: 'ODataLookup',
            DataObject: local_dsTopics,
            Bind: (sel) => {props.dataObject.current.Topic_ID = sel.ID, props.dataObject.current.Topic = sel.NameAndDescription;},
            Columns: [
                {field:'NameAndDescription', headerName:'Name And Description', width:300}
            ],
            WhereClause: "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%'",
            Group:"Other"
        },
        {
            Label: $t('Project Code'),
            Field: "ProjectCode",
            RequiredField: "ProjectCode",
            InputType: 'ODataLookup',
            DataObject: local_dsProjectCodes,
            Bind: (sel) => {props.dataObject.current.ProjectCode_ID = sel.ID, props.dataObject.current.ProjectCode = sel.Name;},
            Columns: [
                {field:'ID', headerName:'ID', width:100},
                {field:'Name', headerName:'Name', width:200},
                {field:'OrgUnit', headerName:'OrgUnit', width:200},
            ],
            WhereClause: "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%'",
            Group:"Project Information"
        },
        {
            Label: $t('Discipline'),
            Field: "DisciplineNameAndTitle",
            RequiredField: "Discipline",
            InputType: 'ODataLookup',
            DataObject: local_dsDisciplines,
            Bind: (sel) => {props.dataObject.current.Discipline_ID = sel.ID, props.dataObject.current.DisciplineNameAndTitle = sel.NameAndTitle;},
            Columns: [
                {field:'ID', headerName:'ID', width:100},
                {field:'Name', headerName:'Name', width:200},
                {field:'Title', headerName:'Title', width:200},
            ],
            WhereClause: "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%' AND Closed IS NULL",
            Group:"Project Information"
        },
        {
            Label: $t('Project Phase'),
            Field: "ProjectPhase",
            RequiredField: "ProjectPhase",
            InputType: 'ODataLookup',
            DataObject: local_dsProjectPhases,
            Bind: (sel) => {props.dataObject.current.ProjectPhase_ID = sel.ID, props.dataObject.current.ProjectPhase = sel.Name;},
            Columns: [
                {field:'ID', headerName:'ID', width:100},
                {field:'Name', headerName:'Name', width:200},
                {field:'Title', headerName:'Title', width:200},
            ],
            Group:"Project Information"
        },
        {
            Label: $t('Main Area'),
            Field: "MainArea",
            RequiredField: "MainArea",
            InputType: 'ODataLookup',
            DataObject: local_dsMainAreas,
            Bind: (sel) => {props.dataObject.current.MainArea_ID = sel.ID, props.dataObject.current.MainArea = sel.Name;},
            Columns: [
                {field:'Name', headerName:'Name', width:200},
                {field:'Title', headerName:'Title', width:200},
            ],
            WhereClause: "(DocumentGroup_ID = " + props.dataObject.current.DocumentGroup_ID + " OR DocumentGroup_ID IS NULL) AND '" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]",
            Group:"Project Information"
        },
        {
            Label: $t('Sub Projects'),
            Field: "SubProjects",
            RequiredField: "SubProjects",
            InputType: 'ODataLookupCheckBoxes',
            DataObject: local_dsSubProjects,
            Columns: [
                {field:'Name', headerName:'Name', width:400}
            ],
            WhereClause: `${props.dataObject.current.Domain_ID} = Domain_ID`,
            Group:"Project Information"
        },
        {
            Label: $t('Involved Contracts'),
            Field: "InvolvedContracts",
            RequiredField: "InvolvedContracts",
            InputType: 'ODataLookupCheckBoxes',
            DataObject: local_dsOrgUnitsLkp,
            Columns: [
                {field:'NameAndTitle', headerName:$t('Org Unit'), width:300}
            ],
            WhereClause: "[IdPath] LIKE '" + context.idPath + "%'",
            Group:"Other"
        },
        {
            Label: $t('Review Class'),
            Field: "ReviewClass",
            InputType: 'ODataLookup',
            DataObject: local_dsReviewClasses,
            Bind: sel => {props.dataObject.current.ReviewClass_ID = sel.ID, props.dataObject.current.ReviewClass = sel.Name;},
            Columns: [
                {field:'ID', headerName:'ID', width:100},
                {field:'Name', headerName:'Name', width:200},
            ],
            Group:"Review"
        },
        {
            Label: $t('Keywords'),
            Field: "Keywords",
            InputType: 'input',
            Group:"Other"
        },
        {
            Label: $t('Progress / Weighting'),
            Field: "Progress",
            Field2: "ProgressWeighting",
            InputType: 'input2x',
            Group:"Delivery Plan"
        },
        {
            Label: $t('WBS'),
            Field: "WBSNameAndTitle",
            InputType: 'ODataLookup',
            DataObject: local_dsWBS,
            Bind: sel => {props.dataObject.current.WBS_ID = sel.ID, props.dataObject.current.WBSNameAndTitle = sel.NameAndTitle;},
            Columns: [
                {field:'Parent', headerName:'Parent', width:200},
                {field:'Title', headerName:'Title', width:200},
                {field:'Name', headerName:'Name', width:200}
            ],
            Group:"Other"
        },
        {
            Label: $t('Comments'),
            Field: "Comments",
            InputType: 'OTextArea',
            Group:"Other"
        },
        {
            Label: $t('Next Delivery Date'),
            Field: "NextDeliveryDate",
            InputType: 'o-date-picker',
            Group:"Delivery Plan"
        },
        {
            Label: $t('Planned IDC'),
            Field: "PlannedIDC",
            RequiredField: "PlannedIDC",
            InputType: 'o-date-picker',
            Title: $t('Planned Interdisciplinary Check'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Planned IFR'),
            Field: "PlannedIFR",
            RequiredField: "PlannedIFR",
            InputType: 'o-date-picker',
            Title: $t('Planned Issued for Review'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Planned IFD'),
            Field: "PlannedIFD",
            RequiredField: "PlannedIFD",
            InputType: 'o-date-picker',
            Title: $t('Planned Issued for Design'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Planned IFI/IFU/IFC'),
            Field: "PlannedIFU",
            RequiredField: "PlannedIFU",
            InputType: 'o-date-picker',
            Title: $t('Planned Issued for Use / Construction'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Planned ASB'),
            Field: "PlannedASB",
            RequiredField: "PlannedASB",
            InputType: 'o-date-picker',
            Title: $t('Planned As Built'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Planned Start'),
            Field: "PlannedStart",
            RequiredField: "PlannedStart",
            InputType: 'o-date-picker',
            Group:"Delivery Plan"
        },
        {
            Label: $t('Planned Verified'),
            Field: "PlannedVerified",
            RequiredField: "PlannedVerified",
            InputType: 'o-date-picker',
            Group:"Delivery Plan"
        },
        {
            Label: $t('Planned Released'),
            Field: "PlannedReleased",
            RequiredField: "PlannedReleased",
            InputType: 'o-date-picker',
            Group:"Delivery Plan"
        },
        {
            Label: $t('Planned Approved'),
            Field: "PlannedApproved",
            InputType: 'o-date-picker',
            Group:"Delivery Plan"
        },
        {
            Label: $t('Planned TPR'),
            Field: "PlannedTPR",
            RequiredField: "PlannedTPR",
            InputType: 'o-date-picker',
            Title: $t('Planned Third Party Review'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Forecast IDC'),
            Field: "ForecastIDC",
            RequiredField: "ForecastIDC",
            InputType: 'o-date-picker',
            Title: $t('Forecast Interdisciplinary Check'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Forecast IFR'),
            Field: "ForecastIFR",
            RequiredField: "ForecastIFR",
            InputType: 'o-date-picker',
            Title: $t('Forecast Issued for Review'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Forecast IFD'),
            Field: "ForecastIFD",
            RequiredField: "ForecastIFD",
            InputType: 'o-date-picker',
            Title: $t('Forecast Issued for Design'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Forecast IFI/IFU/IFC'),
            Field: "ForecastIFU",
            RequiredField: "ForecastIFU",
            InputType: 'o-date-picker',
            Title: $t('Forecast Issued for Use / Construction'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Forecast ASB'),
            Field: "ForecastASB",
            RequiredField: "ForecastASB",
            InputType: 'o-date-picker',
            Title: $t('Forecast As Built'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Forecast Start'),
            Field: "ForecastStart",
            RequiredField: "ForecastStart",
            InputType: 'o-date-picker',
            Group:"Delivery Plan"
        },
        {
            Label: $t('Forecast Verified'),
            Field: "ForecastVerified",
            RequiredField: "ForecastVerified",
            InputType: 'o-date-picker',
            Group:"Delivery Plan"
        },
        {
            Label: $t('Forecast Released'),
            Field: "ForecastReleased",
            RequiredField: "ForecastReleased",
            InputType: 'o-date-picker',
            Group:"Delivery Plan"
        },
        {
            Label: $t('Forecast Approved'),
            Field: "ForecastApproved",
            InputType: 'o-date-picker',
            Group:"Delivery Plan"
        },
        {
            Label: $t('Forecast TPR'),
            Field: "ForecastTPR",
            RequiredField: "ForecastTPR",
            InputType: 'o-date-picker',
            Title: $t('Forecast Third Party Review'),
            Group:"Delivery Plan"
        },
        {
            Label: $t('Expiry Tracked By'),
            Field: "ExpiryTrackedBy",
            InputType: 'input',
            Group:"Other"
        },
        {
            Label: $t('Expiry Date'),
            Field: "ExpiryDate",
            InputType: 'o-date-picker',
            Group:"Other"
        },
        {
            Label: $t('Person (Applies To)'),
            Field: "Person",
            RequiredField: "Person",
            InputType: 'OPersonsLookup',
            Bind: (sel) => {props.dataObject.current.Person_ID = sel.ID, props.dataObject.current.Person = sel.Name;},
            Group:"Other"
        },
        {
            Label: $t('Retention Code'),
            Field: "RetentionCode",
            InputType: 'ODataLookup',
            DataObject: local_dsRetentionCodes,
            Bind: sel => {props.dataObject.current.RetentionCode = sel.Code;},
            Columns: [
                {field:'Code', headerName:'Code', width:200},
                {field:'Description', headerName:'Description', width:200}
            ],
            Group:"Other"
        },
        {
            Label: $t('Archived'),
            Field: "ArchivedDate",
            InputType: "o-date-picker",
            Group:"Other"
        },
        {
            Label: $t('Archived By'),
            Field: 'ArchivedBy',
            InputType: 'input',
            Disabled: true,
            Group:"Other"
        },
        {
            Label: $t('Obsolete'),
            Field: 'Obsolete',
            InputType: "o-date-picker",
            Group:"General"
        },
        {
            Label: $t('Obsolete Reason'),
            Field: "ObsoleteReason",
            InputType: 'ODataLookup',
            DataObject: local_dsVoidReasons,
            Bind: sel => {props.dataObject.current.ObsoleteReason_ID = sel.VoidReason_ID; props.dataObject.current.ObsoleteReason= sel.Name;},
            Columns: [
                {field:'Name', headerName:'Name', width:300}
            ],
            Group:"General"
        },
        {
            Label: $t('Document Package'),
            Field: "PackageNameAndTitle",
            RequiredField: "DocumentPackage",
            InputType: 'ODataLookup',
            DataObject: local_dsDocumentPackages,
            Bind: sel => {props.dataObject.current.PackageNameAndTitle = sel.NameAndTitle; props.dataObject.current.Package_ID = sel.ID;},
            WhereClause:`'${props.dataObject.current.OrgUnitIdPath}' LIKE IdPath`,
            Columns: [
                {field:'Name', width:150},{field:'Title', width:300}
            ],
            Group:"Project Information"
        },
        {
            Label: $t('Distribution Requirements'),
            Field: "DistributionRequirements",
            InputType: 'ODataLookupCheckBoxes',
            DataObject: local_dsDistReqTypes,
            Columns: [
                {field:'Name', headerName:'Name', width:300}
            ],
            WhereClause: `IdPath IS NULL OR '${props.dataObject.current.OrgUnitIdPath}' LIKE IdPath`,
            Group:"Other"
        },
        {
            Label: $t('Checklist'),
            Field: "Checklist",
            InputType: 'ODataLookup',
            DataObject: local_dsChecklistsLkp,
            Columns: [
                {field:'Name', width:150},{field:'Title', width:300}
            ],
            Bind: sel => {props.dataObject.current.Checklist = sel.Name; props.dataObject.current.Checklist_ID = sel.ID;},
            WhereClause: `AccessOrgUnit_ID = '${props.dataObject.current.OrgUnit_ID}'`,
            Group:"Other"
        },
    ]
}

setDefaultAttributes();

const attributeGroups = [
    {Name: "Required",SortOrder:1, Collapsed:false},
    {Name: "General",SortOrder:2, Collapsed:false},
    {Name: "Review",SortOrder:3, Collapsed:true},
    {Name: "Contractor Info",SortOrder:4, Collapsed:true},
    {Name: "Client Info",SortOrder:5, Collapsed:true},
    {Name: "Delivery Plan",SortOrder:6, Collapsed:true},
    {Name: "Project Information",SortOrder:7, Collapsed:true},
    {Name: "External Process",SortOrder:8, Collapsed:true},
    {Name: "Asset",SortOrder:9, Collapsed:true},
    {Name: "Other",SortOrder:10, Collapsed:true},
]


const groupedAttributes = computed(() => {
    // Group the attributes by the 'group' property
    const groups = attributes.value.reduce((groups, attribute) => {
        const groupName = attribute.Group || 'Ungrouped';
        if (!groups[groupName]) {
            groups[groupName] = {
                name: groupName,
                attributes: [],
                sortIndex:attributeGroups.find((group) => group.Name === groupName).SortOrder,
                collapsed:attributeGroups.find((group) => group.Name === groupName).Collapsed
            };
        }
        groups[groupName].attributes.push(attribute);
        return groups;
    }, {});

    // Convert the groups object to an array
    return Object.values(groups).sort((a, b) => a.sortIndex - b.sortIndex);
});

const disabledDO = computed(() => {
    return (props.dataObject.current.Deleted || props.dataObject.current.Obsolete || (props.dataObject.current.Released && !props.dataObject.current.CanAdministrateDocument))
});

const getRequiredFields = () => {
    requiredFields.value = {}
    local_dsErrors.data.map((row)=>{
        if(!props.dataObject.current[row.FieldName]){
            requiredFields.value[row.FieldName] = true;
            requiredFields.value[row.FieldName+'Error'] = row.Error;
        }
    })
}

props.dataObject.on('AfterSave',(pFieldsUpdated) => {
    const updateAttributes = computed(() => {
        if(pFieldsUpdated.values.hasOwnProperty('DocumentType_ID') || pFieldsUpdated.values.hasOwnProperty('OrgUnit_ID'))
            return true;
        else
            return false;
    });

    local_dsErrors.load().then(()=>{
        if(updateAttributes.value){
            getRequiredFields();
            setDefaultAttributes();
            sortAttributes();
        }
    });
})

local_dsErrors.on('DataLoaded', () => {
    getRequiredFields();
})

const sortAttributes = () =>{
    attributes.value.sort((a,b) => {
        if(requiredFields.value[a.RequiredField ?? a.Field] && !requiredFields.value[b.RequiredField ?? b.Field]){
            a.Required = true;
            a.Group = "Required"
            return -1;
        }
        if(!requiredFields.value[a.RequiredField ?? a.Field] && requiredFields.value[b.RequiredField ?? b.Field]){
            b.Required = true;
            b.Group = "Required"
            return 1;
        }
        if(requiredFields.value[a.RequiredField ?? a.Field] &&  requiredFields.value[b.RequiredField ?? b.Field]){
            a.Required = true;
            b.Required = true;
            a.Group = "Required"
            b.Group = "Required"
        }
        return 0;
    })

    attributes.value.sort((a,b) => {
        if(!requiredFields.value[a.RequiredField ?? a.Field] && !props.dataObject.current[a.Field] && props.dataObject.current[b.Field]){
            b.IsSet = true;
            return 1;
        }
        if(!requiredFields.value[b.RequiredField ?? b.Field] && !props.dataObject.current[b.Field] && props.dataObject.current[a.Field]){
            a.IsSet = true;
            return -1;
        }
        if(props.dataObject.current[a.Field] && props.dataObject.current[b.Field]){
            a.IsSet = true;
            b.IsSet = true;
        }
        return 0;
    })

    isViewerLoading.value = false
}

local_dsErrors.load().then(() => {
    sortAttributes();
});

// checkboxes lookup helpers (parse/save in json)
const getCheckboxesJsonValue = (pJson) => {
    if (!pJson){
        return ''
    }
    try {
        return JSON.parse(pJson).join(', ');
    } catch (e) {
        return pJson;
    }  
}
function checkBoxesIsSelected(plkpItem, pJson, pField){
    if (!pJson){
        return
    }
    try {
        plkpItem.isSelected = JSON.parse(pJson).includes(plkpItem[pField]);
    } catch (e) {
        plkpItem.isSelected = pJson.split(', ').includes(plkpItem[pField]);
    } 
}
function setCheckBoxValues(pSel, pColumn, pField){
    if(pSel && pSel.length){
        props.dataObject.current[pColumn] = JSON.stringify(pSel.map(item => item[pField]));
    }else{
        props.dataObject.current[pColumn] = null;
    }
    saveDataObject();
}

</script>

<style scoped>
.spinner-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.spinner-border {
  width: 5rem;
  height: 5rem;
}

.overlay__inner{
    display:none;
}

</style>